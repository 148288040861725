
import { useGetUsersQuery } from "../../redux/services/UserApi";
import { Card } from "react-bootstrap";

const UserList = () => {

// Using a query hook automatically fetches data and returns query values
const { data, error, isLoading } =  useGetUsersQuery()

// Individual hooks are also accessible under the generated endpoints:
// const { data, error, isLoading } = pokemonApi.endpoints.getPokemonByName.useQuery('bulbasaur')

// render UI based on data and loading state
  

if(error){
  return 'ERROR:' + error;
}
if(isLoading){
  return 'Loading...';
}
if(!data){
  return "NO USERS"
}

  return (
    <div className="userRow">
      {data.map(user => (
        <div key={user.firstName} user={user} >
          <h1>{user.firstName}</h1>
          <Card>
            {JSON.stringify(user)}
          </Card>
        </div>
      ))}
    </div>
  );
};

export default UserList;
