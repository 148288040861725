import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '../../thor/model'
import { BASE_PATH } from '../../thor/src'

type UsersResponse = User[]
export const userApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_PATH}),
  tagTypes: ['User'],
  endpoints: (build) => ({
    getUsers: build.query<UsersResponse, void>({
      query: () => 'User',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'User' as const, id })),
              { type: 'User', id: 'LIST' },
            ]
          : [{ type: 'User', id: 'LIST' }],
    }),
    addUser: build.mutation<User, Partial<User>>({
      query: (body) => ({
        url: `User`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    getUser: build.query<User, string>({
      query: (id) => `User/${id}`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    updateUser: build.mutation<void, Pick<User, 'id'> & Partial<User>>({
      query: ({ id, ...patch }) => ({
        url: `User/${id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', id, (draft) => {
            Object.assign(draft, patch)
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    deleteUser: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `User/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'User', id }],
    }),
  }),
})

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi
