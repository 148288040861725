import React from "react";
import { Fade } from "react-awesome-reveal"; // For smooth fading transitions
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaEnvelope, FaFacebookF, FaInstagram } from "react-icons/fa";

function Tos() {
  return (
    <div className="App">
      
      <Card bg="secondary" text="white" className="text-center py-5">
      
      
        <Fade cascade>
       <h3>TRUSTLOVE Terms of Service</h3>
        </Fade>

        <div style={{height:"400px", wordWrap:"break-word", fontFamily:"courier", overflowY:"scroll"}}>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
          <br/><br/>
          Lorem ipsum dolor sit amet, consectetLorem ipsum dolor sit amet,
          consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor sit
          amet, consectetLorem ipsum dolor sit amet, consectetLorem ipsum dolor
          sit amet, consectet
        </div>
      
        Have a question or comment? We'd love to hear from you. Send us a message and
              we'll respond as soon as possible.

            <h4>Please use the social media or email links to contact us directly:</h4>

            <Container className="social-links">
            <a
                    href="https://www.facebook.com/profile.php?id=61561425107918"
                    className="me-3"
                  >
                    <FaFacebookF />
                  </a>

                  <a
                    href="https://www.instagram.com/trust.l0v3"
                    className="me-3"
                  >
                    <FaInstagram />
                  </a>

                  <a href="mailto:john@trustlove.io">
                    <FaEnvelope />
                  </a>


            </Container>

      <br/>
            
      {false && (
        <Fade cascade>
          <Row className="justify-content-center my-4">
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Your Message"
                  />
                </Form.Group>

                <button type="submit">Submit</button>
              </Form>
            </Col>
          </Row>
        </Fade>
      )}
      </Card>
    </div>
  );
}

export default Tos;
