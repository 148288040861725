// create a self-contained chatgpt component

import OpenAI from 'openai';


import React, { useRef, useState } from 'react';
import { Accordion, Badge, Card, Form, Spinner } from 'react-bootstrap';

const SageChat = () => {
  const [messages, setMessages] = useState<{ role: 'user' | 'assistant'; content: string }[]>([]);
  const [input, setInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const chatHistoryRef = useRef<HTMLDivElement>(null);

  // Initialize OpenAI client
  const openai = new OpenAI({
    apiKey: process.env['REACT_APP_OPENAI_KEY'], 
    dangerouslyAllowBrowser: true 
    // This is the default and can be omitted
  });
  
  const sendMessage = async () => {
    if (!input.trim()) return;
    debugger
    // Add user message to the chat history
    setMessages([...messages, { role: 'user', content: input }]);
    setIsLoading(true);
    setInput('');

    try {
      // Call the OpenAI API to get a response from the assistant
       const response = await openai.fetchWithTimeout();

      // Assuming the API returns an object with 'choices', and the assistant's response is in the 'message' property
       const assistantMessage = response.choices[0].message.content;

      // Add assistant's response to the chat history
      setMessages((prev) => [...prev, { role: 'assistant', content: assistantMessage }]);
    } catch (err) {
      setError('Failed to get response from OpenAI');
    } finally {
      setIsLoading(false);
    }
};

    // Scroll to the bottom of the chat history
  //  if (chatHistoryRef.current) {
    //  chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
   // }
  //};

  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Chat History</Accordion.Header>
          <Accordion.Body style={{ height: '300px', overflowY: 'scroll' }} ref={chatHistoryRef}>
            {messages.map((msg, index) => (
              <Card key={index} className="mb-2">
                <Card.Body>
                  <Badge bg={msg.role === 'user' ? 'primary' : 'secondary'}>{msg.role}</Badge>
                  <Card.Text>{msg.content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
            {isLoading && <Spinner animation="border" />}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {error && <p className="text-danger">{error}</p>}

      <Form>
        <Form.Group controlId="userInput">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
        </Form.Group>
        <button variant="primary" onClick={sendMessage} className="mt-2">
          Send <i className="bi bi-send-fill"></i> {/* Bootstrap icon */}
        </button>
      </Form>
    </div>
  );
};

export default SageChat;
