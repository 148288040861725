import React from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Provider as ReduxQueryProvider } from "redux-query-react";

import App from "./App";

// Quartz
// Sandstone
// Simplex
// solar
// superhero
// United
// > darkly
// flatly
///!!!  Vapor
// Yeti
// slate

import "./css/quartz/bootstrap.css";

import "./App.css";

import "./index.css";

import reportWebVitals from "./reportWebVitals";

import store from "./redux/store";

export const getQueries = (state) => state.queries;

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={getQueries}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ReduxQueryProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
